import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import AtelierCollectionCard from "./AtelierCollectionCard";


var paragraphs = require('lines-to-paragraphs');


class AtelierModelageCollectionRoll extends React.Component {

    render() {
        const { data, type } = this.props

        const { edges: collections } = data.allShopifyCollection

        return (
          <div className="columns is-multiline">
              {collections &&
              collections.map(({ node: collection }) => {
                  if (collection.products.length > 0) {
                      if (collection.title.toLowerCase().includes("modelage")) {
                          return (
                            <AtelierCollectionCard collection={collection}/>
                          )
                      }
                    }
                }
              )}
          </div>
        )
    }
}

AtelierModelageCollectionRoll.propTypes = {
    data: PropTypes.shape({
        allShopifyCollection: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
    type : PropTypes.string
}

export default () => (

  <StaticQuery
    query={graphql`
      query AtelierCollectionRollQuery {
        allShopifyCollection {
          edges {
              node {
                id
                title
                description
                descriptionHtml
                productsCount
                products {
                  id
                  title
                  shopifyId
                  handle
                  priceRangeV2 {
                    maxVariantPrice {
                      amount
                    }
                    minVariantPrice {
                      amount
                    }
                  }
                  featuredImage {
                    gatsbyImageData
                    src
                    altText
                  }
                }
                image {
                  gatsbyImageData
                  altText
                  src
                }
              }
            }
        }
      }
    `}
    render={(data, type, count) => <AtelierModelageCollectionRoll data={data} count={count} />}
  />
)
