import React from 'react'
import Layout from '../../components/layout/Layout'
import PreviewCompatibleImage from '../../components/image/PreviewCompatibleImage'
import CeramiqueBanner from "../../components/ceramique/CeramiqueBanner";
import { useIsMobile } from "../../hooks/useIsMobile";
import AtelierModelageCollectionRoll from "../../components/atelier/AtelierModelageCollectionRoll";
import GiftCardBanner from "../../components/gift-card/GiftCardBanner";


const imageInfo1 = {
  "alt": "Des modèles de céramique réalisés par des participants aux ateliers",
  "image": "/img/v2/Cateliersmodelage-min.JPEG",
  "maxWidth": 350
}

const AtelierModelagePage = () => {


  const {isMobile} = useIsMobile()

  return (
    <Layout>
      <section className="section">
        <div className="container">

          <div className="content">
            <div className="columns">
              <div className="column is-7" style={{paddingTop : isMobile ? "0.75rem" : "3rem"}}>
                <p>Venez découvrir la céramique à travers des ateliers de modelage qui permettent de réaliser des pièces très réussies, à la main sans tour et cela même en un seul cours! </p>
                <p>Débutants comme confirmés, vous pouvez participer à une séance ponctuelle autour d'un thème ou une technique imposée. </p>
                <p>Vous pouvez aussi choisir de venir de manière répétée afin d'approfondir votre pratique, en étant encadré ou en autonomie.</p>
                <p></p>
                <p></p>
                <GiftCardBanner/>

              </div>
              <div className="column has-text-centered is-3 is-justify-content-center is-flex" style={{margin:'auto', padding : "1rem"}}>
                <PreviewCompatibleImage imageInfo={imageInfo1} />
              </div>
            </div>
            {/*<GiftCardBanner/>*/}


            <div className="content is-offset-1" style={{marginTop: '1rem', marginBottom: '1rem'}}>

              <p></p>
              <div>
                {/*<h1 className="has-text-weight-bold has-text-centered is-size-1">*/}
                {/*  Les ateliers modelage :*/}
                {/*</h1>*/}
              </div>
              <AtelierModelageCollectionRoll type={"modelage"}/>
              {/*<CeramiqueBanner/>*/}
            </div>
          </div>
        </div>
      </section>
    </Layout >
  )}


export default AtelierModelagePage
